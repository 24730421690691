import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Loader from "views/universal/Loader/Loader";
import { Col, Row } from "react-bootstrap";
import { centimesToCurrency } from "utils/utils";
import Vigilance from "./Options/Vigilance";
import ToDelete from "./Options/ToDelete";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faShoppingBag,
  faSmile
} from "@fortawesome/free-solid-svg-icons";
import PopupOrders from "./PopupOrders"
import { customersOperations } from "state/ducks/store/customers";

const Customer = (props) => {
  const { customer, establishment, openPopupCustomerHandle, sendSMSHandle, getCustomerPool, getCustomerAdvantage } =
    props;

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);

  const getCustomerOrder = (customer, e) => {
    e.preventDefault();
    setIsModalOpen(true);
    setIsLoadingPopup(true);
    dispatch(customersOperations.getStoreCustomerOrders(customer)).then(
      (result) => {
        setIsLoadingPopup(false);
      },
      (error) => {
        setIsModalOpen(false);
        setIsLoadingPopup(false);
      }
    )
  };

  return (
    <>
      {customer && customer.isLoading ? (
        <Loader items={1} />
      ) : (
        customer.id && (
          <>
            <Info noGutters>
              <Col xs={12} sm={6} className="vAlign">
                <small>Nom prénom - Id {customer.id}</small>
                <Title>{customer.lastname + " " + customer.firstname}</Title>
                {customer?.companion_price !== null && 
                  <Title>Abonnée Compagnon ({centimesToCurrency(customer?.companion_price || 0)})</Title>
                }
                <Coordonate>
                  {customer.email}
                  <br />
                  {customer.phone}
                  <br />
                  Code parrainage : {customer.sponsor_code}
                </Coordonate>
                <ButtonBtsp
                  onClick={() => openPopupCustomerHandle(customer)}
                  disabled={customer.isLoading}
                  variant="outline-light"
                >
                  Modifier ce client
                </ButtonBtsp>
              </Col>
              <Col xs={12} sm={6} className="vAlign">
                <small>Code de connexion boutique kiosque</small>
                <Coordonate>{customer.code_id}</Coordonate>
                 
                  <ButtonBtsp
                    onClick={() => {
                      if (
                        window.confirm(
                          "Êtes-vous sûr de vouloir envoyer le code de connexion par SMS ?"
                        )
                      ) {
                        sendSMSHandle(customer);
                      }
                    }}
                    disabled={customer.isLoading}
                    variant="outline-light"
                  >
                    Envoyer son code de connexion par SMS
                  </ButtonBtsp>
                
              </Col>

            </Info>
            
            <Row className="mt-3">
              <Col xs={12} sm={4}>
                <div className="d-grid gap-2">
                  <ButtonBtsp
                    variant="dark"
                    size="lg"
                    full
                    onClick={(e) => getCustomerOrder(customer, e)}
                  >
                    <FontAwesomeIcon icon={faShoppingBag} /> Voir ses commandes
                  </ButtonBtsp>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <ButtonBtsp
                  size="lg"
                  onClick={(e) => getCustomerPool(customer.id, e)}
                  variant="info"
                  full
                >
                  <FontAwesomeIcon icon={faWallet} /> Voir son porte-monnaie
                </ButtonBtsp>
              </Col>
              <Col xs={12} sm={4}>
                <ButtonBtsp
                  onClick={(e) => getCustomerAdvantage(customer.id, e)}
                  variant="success"
                  size="lg"
                  full
                >
                  <FontAwesomeIcon icon={faSmile} /> Voir ses avantages
                </ButtonBtsp>
              </Col>
            </Row>

            <Options>
              <h4>Options :</h4>
              <ToDelete customer={customer} establishment={establishment}/>
              <br /><br />
              <Vigilance customer={customer} />
              <br />
              {/* On ne l'utilise plus au date de 03/10/24 car le panneau SAV fait la meme chose de facon centraliser */}
              {/* <LastOrderAmountOffer
                customer={customer} establishment={establishment}
              /> */}
            </Options>

          </>
        )
      )}

      <PopupOrders
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        onEscapeKeyDown={() => setIsModalOpen(false)}
        customer={customer}
        isLoading={isLoadingPopup}
      />
      
    </>
  );
};

const Info = styled(Row)`
  background: linear-gradient(130deg, rgb(66, 152, 83), rgb(144, 163, 69));
  padding: 1.5rem 1rem;
  border-radius: 4px;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 0rem;
`;

const Options = styled.div`
  color: ${(props) => props.theme.colors.black};
  background-color: white;
  margin: 1rem 0;
  padding: 1rem 1rem;
  border-radius: 4px;
  border: solid 1px #ddd;
`;

const Coordonate = styled.h3`
  color: ${(props) => props.theme.colors.white};
  font-style: italic;
  font-size: 1.4rem;
`;

const ButtonBtsp = styled(Button)`
  display: ${(props) => props.full ? 'block' : 'inline-block'};
  width: ${(props) => props.full ? '100%' : 'fit-content'};
  
`;

Customer.propTypes = {};

Customer.defaultProps = {};

export default Customer;
